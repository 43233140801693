import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  return (
    <dl className={props.className}>
      <div className="Row">
        <dt>販売業者の正式名称</dt>
        <dd>React User Japan Group</dd>
      </div>

      <div className="Row">
        <dt>所在地</dt>
        <dd>東京都渋谷区千駄ヶ谷3-3-20 301</dd>
      </div>

      <div className="Row">
        <dt>電話番号</dt>
        <dd>090-1003-7691</dd>
      </div>

      <div className="Row">
        <dt>メールアドレス</dt>
        <dd>reactjapanconf@gmail.com</dd>
      </div>

      <div className="Row">
        <dt>運営統括責任者</dt>
        <dd>向井 咲人</dd>
      </div>

      <div className="Row">
        <dt>商品代金以外の必要料金</dt>
        <dd>なし</dd>
      </div>

      <div className="Row">
        <dt>返品・交換の方法</dt>
        <dd>メールにてお問い合わせください</dd>
      </div>

      <div className="Row">
        <dt>支払い方法</dt>
        <dd>
          クレジットカード、現金払い
          <br />
          一部Peatix
          Inc.が提供する決済システムを利用しておりますので、同社の定めるところに従います。
          <br />
          <a
            href="https://about.peatix.com/ja/legal-company.html"
            target="_blank"
          >
            https://about.peatix.com/ja/legal-company.html
          </a>
        </dd>
      </div>

      <div className="Row">
        <dt>引き渡し時期</dt>
        <dd>決済手続き後、メール送信を行うことでチケット引渡が完了</dd>
      </div>

      <div className="Row">
        <dt>支払い時期</dt>
        <dd>メールにてお問い合わせください</dd>
      </div>

      <div className="Row">
        <dt>販売価格</dt>
        <dd>一般：¥8,000、学生：¥2,000</dd>
      </div>

      <div className="Row">
        <dt>返品・キャンセル</dt>
        <dd>
          お支払い後のキャンセルは受け付けることができません。
          <br />
          あらかじめご了承ください。
          <br />
          <br />
          イベント側都合のイベントの中止の場合は払い戻します。
          <br />
          この際、チケット費用のみの返金となり、旅費やホテル代については補償対象外となります。
        </dd>
      </div>
    </dl>
  )
}
// ______________________________________________________
//
const spStyle = `
  .Row {
    display: block;
    &:not(:first-child) {
      margin-top: 28px;
    }
    > dt {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`

const StyledComponent = styled(Component)`
  .Row {
    display: flex;
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: 40px;
    }
    > dt {
      width: 216px;
      color: ${({ theme }) => theme.colors.black};
      font-weight: bold;
      font-size: 16px;
      line-height: 1.2;
    }
    > dd {
      color: ${({ theme }) => theme.colors.black};
      font-size: 16px;
      line-height: 1.7;
      a {
        color: ${({ theme }) => theme.colors.black};
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`

// ______________________________________________________
//
export default StyledComponent
