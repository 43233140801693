import React, { useCallback } from "react"
import styled from "styled-components"
import { PagesTopQuery } from "@/types"
import UnderHero from "@components/common/underHero"
import Header from "@components/common/header"
import Footer from "@components/common/footer"
import Content from "./content"
import { UseToggleLanguage } from "@components/hooks"
// ______________________________________________________
//
type Props = PagesTopQuery &
  UseToggleLanguage & {
    className?: string
  }
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  const toggleLanguage = useCallback(() => {
    if (props.lang === "ja") {
      props.toggleToEN()
    } else if (props.lang === "en-US") {
      props.toggleToJA()
    }
  }, [props.lang])
  return (
    <div className={props.className}>
      <Header toggleLanguage={toggleLanguage} lang={props.lang} />
      <UnderHero />
      <Content />
      <Footer
        lists={
          props.lang === "ja"
            ? (props.site?.siteMetadata?.footerLinkListsJA as any)
            : (props.site?.siteMetadata?.footerLinkListsEN as any)
        }
      />
    </div>
  )
}
// ______________________________________________________
//
const StyledComponent = styled(Component)``

// ______________________________________________________
//
export default StyledComponent
