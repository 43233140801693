import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import List from "../list"
import Button from "@components/common/button"
// ______________________________________________________
//
type Props = {
  className?: string
}
// ______________________________________________________
//
const Component: React.FC<Props> = props => {
  return (
    <div className={props.className}>
      <div className="Inner">
        <h1 className="Title">特定商取引法に関する表記</h1>
        <List />
        <div className="ButtonWrap">
          <Button
            type="back"
            href="/2020"
            className="Button"
            label="BACK TO HOME"
            disabled={false}
          />
        </div>
      </div>
    </div>
  )
}
// ______________________________________________________
//
const spStyle = `
  .Inner {
    padding: 32px 20px 48px;
  }
  .Title {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.2;
  }
  .ButtonWrap {
    margin-top: 48px;
  }
`

const StyledComponent = styled(Component)`
  background-color: ${({ theme }) => theme.colors.ivory};
  .Inner {
    max-width: ${927 + 80}px;
    margin: 0 auto;
    padding: 64px 40px 136px;
    box-sizing: border-box;
  }
  .Title {
    margin-bottom: 58px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: center;
  }
  .ButtonWrap {
    margin-top: 84px;
    text-align: center;
  }
  .Button {
    background-color: ${({ theme }) => theme.colors.scarlet};
    color: #fff;
    svg path {
      fill: #fff;
    }
  }
  ${media.lessThan("medium")`${spStyle}`}
`

// ______________________________________________________
//
export default StyledComponent
