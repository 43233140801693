import React from "react"
import { graphql } from "gatsby"
import { PagesTopQuery } from "@/types"
import Layout from "@components/layouts"
import Meta from "@components/layouts/meta"
import Page from "@components/pages/2020/tokusho"
import { useToggleLanguage } from "@components/hooks"

// ______________________________________________________
//
type Props = { data: PagesTopQuery }
// ______________________________________________________
//
const Component: React.FC<Props> = ({ data }) => {
  const pageTitle = `特定商取引法に関する表記 | ${data.site?.siteMetadata?.title}`
  return (
    <Layout env={data.site?.siteMetadata?.env ?? ""}>
      <Meta title={pageTitle} />
      <Page {...data} {...useToggleLanguage()} />
    </Layout>
  )
}

// ______________________________________________________
//
export const pageQuery = graphql`
  query PagesTokushoho {
    site {
      siteMetadata {
        env
        title
        menu
        footerLinkListsEN {
          navListItems {
            target
            label
            href
            anchor
          }
        }
        footerLinkListsJA {
          navListItems {
            target
            label
            href
            anchor
          }
        }
      }
    }
  }
`
// ______________________________________________________
//
export default Component
